import { Button } from '@headlessui/react'
import { ChevronLeft, Trash2, User } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Header } from '@/components/header'
import Section from '@/components/Section'
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from '@/components/ui/tooltip'
import { useAuth } from '@/hooks/useAuth'

const Users = () => {
  const { fetchAPI, org } = useAuth()

  const [users, setUsers] = useState<OrgMember[]>([])

  useEffect(() => {
    if (org) {
      fetchAPI('/api/v1/users', { org }).then((data) => {
        setUsers(data)
      })
    }
  }, [org])

  if (!org) {
    return null
  }

  const addUser = () => {
    const emailInput = prompt('Enter one or more email addresses')
    if (!emailInput) return

    // Split by spaces, commas, or newlines, and filter out empty strings
    const emails = emailInput
      .split(/[\s,;]+/)
      .map(email => email.trim())
      .filter(email => email.length > 0)

    // Validate all emails
    const invalidEmails = emails.filter(email => !email.includes('@'))
    if (invalidEmails.length > 0) {
      toast.error(`Invalid email: ${invalidEmails.join(', ')}`)
      return
    }

    fetchAPI(
      '/api/v1/users',
      {},
      {
        method: 'POST',
        body: JSON.stringify({ org, user_emails: emails }),
      },
    ).then((data) => {
      setUsers([...users, ...data.users])
    })
  }

  const deleteUser = (user: OrgMember) => {
    if (!confirm(`Remove ${user.email} from ${org}?`)) {
      return
    }
    fetchAPI(`/api/v1/users/${user.id}`, { org }, { method: 'DELETE' })
      .then(() => {
        setUsers(prevUsers => prevUsers.filter((u) => u.id !== user.id))
        toast.success(`${user.email} removed`)
      })
  }

  const invertIsAdmin = (user: OrgMember) => {
    const isAdmin = user.role === 'administrator'

    if (
      !confirm(
        isAdmin
          ? `Remove ${user.email} from admins?`
          : `Promote ${user.email} to admin?`,
      )
    ) {
      return
    }

    fetchAPI(
      `/api/v1/users/${user.id}`,
      { org },
      {
        method: 'PATCH',
        body: JSON.stringify({
          role: isAdmin ? 'user' : 'administrator',
          org,
        }),
      },
    ).then((data) => {
      setUsers(
        users.map((u) => {
          if (u.id === user.id) {
            return data
          }
          return u
        }),
      )
    })
  }

  return (
    <Section>
      {users.map((user) => (
        <div
          key={user.id}
          className="w-full p-4 border-gray-100 border flex flex-row gap-2 rounded-lg"
        >
          <User />
          <p>{user.email}</p>
          {/* {user.role === 'administrator' &&  */}
          {user.role === 'administrator' ? (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  className="text-lime"
                  onClick={() => invertIsAdmin(user)}
                >
                  Admin
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Remove from admins</p>
              </TooltipContent>
            </Tooltip>
          ) : (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  className="text-lime"
                  onClick={() => invertIsAdmin(user)}
                >
                  User
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Promote to admin</p>
              </TooltipContent>
            </Tooltip>
          )}

          <div className="ml-auto flex flex-row gap-4">
            <button onClick={() => deleteUser(user)}>
              <Trash2 />
            </button>
          </div>
        </div>
      ))}

      <Button
        className="text-lime text-xl font-semibold text-left"
        onClick={() => addUser()}
      >
        + add
      </Button>
    </Section>
  )
}

export const Team: React.FC = () => {
  const { isAdmin } = useAuth()

  return (
    <main className="flex flex-grow overflow-hidden flex-col pb-4">
      <Header />
      <Section>
        <div className="self-center items-center flex-row flex gap-4">
          <Link to="/app">
            <ChevronLeft />
          </Link>
          <h2 className="self-center text-7xl  font-semibold text-lime max-md:max-w-full max-md:text-4xl">
            Manage your team
          </h2>
        </div>
      </Section>
      {isAdmin ? (
        <Users />
      ) : (
        <Section>
          <p className="text-center italic">
            Only org admins can manage the team, and you aren't one. Sorry!
          </p>
        </Section>
      )}
    </main>
  )
}

export default Team
