import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { Header } from '@/components/header'
import { LinksBlock } from '@/components/linksBlock'
import { useAuth } from '@/hooks/useAuth'

const getValidHttpsUrl = (
  url: string,
  memberships: Membership[],
): string | null => {
  const hosts = memberships.map((m) => m.hostname)
  const https = 'https://' + url.replace(/^(https?:\/\/)/, '')

  try {
    const uri = new URL(https)
    if (!hosts.includes(uri.hostname)) {
      return null
    }
    return https
  } catch (e) {
    return null
  }
}

export const Links: React.FC = () => {
  const [links, setLinks] = useState<PolyLink[]>([])
  const [newLink, setNewLink] = useState<string>('')
  const [hasError, setHasError] = useState<boolean>(false)
  const { fetchAPI, org, memberships, setOrg } = useAuth()
  const [sending, setSending] = useState<boolean>(false)

  const validURL = getValidHttpsUrl(newLink, memberships)

  const showSend = validURL && !sending

  useEffect(() => {
    if (!org) return
    setLinks([])
    fetchAPI('/api/v1/link', { org }).then((data) => setLinks(data))
  }, [org])

  const err = (message: string) => {
    setHasError(true)
    toast.error(message)
    setTimeout(() => {
      setHasError(false)
    }, 1000)
    setSending(false)
  }

  const onSubmit = async () => {
    if (!showSend) return

    setSending(true)

    const inputUrl = new URL(validURL)
    const queryParams = inputUrl.search

    let data: PolyLink
    try {
      data = await fetchAPI(
        '/api/v1/link',
        {},
        {
          method: 'POST',
          body: JSON.stringify({ url: validURL, org }),
        },
      )
    } catch (e: any) {
      err(e.message)
      return
    }

    // Append the original query parameters to the response URL
    const responseUrl = new URL(data.url)
    responseUrl.search = queryParams
    data.url = responseUrl.toString()

    await navigator.clipboard.writeText(data.url)
    toast.success('Link created and copied to clipboard')
    setNewLink('')
    setHasError(false)
    setSending(false)

    if (org !== data.org) {
      setOrg(memberships.find((m) => m.hostname === data.org) as Membership)
    } else {
      setLinks((prev) => [data, ...prev])
    }
  }

  return (
    <main className="flex flex-grow overflow-hidden flex-col pb-4">
      <Header />
      <section className="flex flex-col self-center  max-w-[90%] w-[892px]">
        <h2 className="self-center text-7xl  mb-[55px] font-semibold text-lime max-md:max-w-full max-md:text-4xl">
          {'‹polymorphic›'}
        </h2>

        <form className="flex flex-grow flex-col w-full relative">
          <textarea
            placeholder={`Paste https://${org}/... link here`}
            aria-label="Paste your link here"
            className={`pl-8 pt-4 text-2xl font-semibold no-scrollbar rounded-2xl border-2 focus:outline-none focus:ring-0 transition-transform transform
 border-solid bg-transparent placeholder:text-zinc-500 max-md:pl-3 max-md:mr-0.5 max-md:max-w-full h-[66px] resize-none pr-32 ${
   hasError ? 'border-red-600 text-red-600' : 'border-white'
 }`}
            name="link"
            value={newLink}
            onChange={(e) => setNewLink(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                onSubmit()
              }
            }}
          ></textarea>
          {showSend && (
            <button
              type="button"
              onClick={async () => onSubmit()}
              className={`absolute right-[5px] top-1/2 transform -translate-y-1/2 bg-lime text-background px-[25px] py-2 rounded-xl text-2xl font-semibold flex items-center gap-3 h-[56px]`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g clipPath="url(#clip0_2029_411)">
                  <path
                    d="M21.9458 1.04823L1.51935 8.41473C0.797949 8.67489 0.838087 9.70845 1.57749 9.91188L10.958 12.4927C11.2251 12.5662 11.4338 12.7749 11.5073 13.042L14.0881 22.4225C14.2915 23.1619 15.3251 23.2021 15.5853 22.4806L22.9518 2.05422C23.1775 1.42823 22.5718 0.822479 21.9458 1.04823Z"
                    className="fill-background"
                    strokeWidth="2"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2029_411">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className="text-black-main">Send</span>
            </button>
          )}
          {sending && (
            <div className="absolute right-[10px] w-[90px] top-1/2 transform -translate-y-1/2">
              <div className="loader self-center"></div>
            </div>
          )}
        </form>

        <LinksBlock links={links} />
      </section>
    </main>
  )
}

export default Links
