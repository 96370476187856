import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import ErrorBoundary from './components/ErrorBoundary'
import router from './router'

import 'react-toastify/dist/ReactToastify.css'
import { TooltipProvider } from './components/ui/tooltip'

const App: React.FC = () => (
  <ErrorBoundary>
    <TooltipProvider>
      <RouterProvider router={router} />
    </TooltipProvider>
    <ToastContainer />
  </ErrorBoundary>
)
App.displayName = 'App'
export default App
